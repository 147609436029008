// @ts-nocheck
import React, { createContext, useState, ReactNode, useEffect } from "react";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { awsConfig } from "../services/awsConfig";
import { CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { setReports } from "../store/reportsSlice";
import { setUser } from "../store/userSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import api from '../utils/api';

const cachedRoutes = [
  '/home',
  '/discover-segments',
  '/onboarding-page',
]

export const clearAllData = () => {
  sessionStorage.clear()

  localStorage.clear()

  caches.keys().then(keys => {
    keys.forEach(key => caches.delete(key))
  })

  indexedDB.databases().then(dbs => {
    dbs.forEach(db => indexedDB.deleteDatabase(db.name))
  })

  document.cookie = document.cookie.split(';').reduce((newCookie1, keyVal) => {
    var pair = keyVal.trim().split('=')
    if (pair[0]) {
      if (pair[0] !== 'path' && pair[0] !== 'expires') {
        newCookie1 += pair[0] + '=;'
      }
    }
    return newCookie1
  }, 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path:/;')
}

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  authData: any;
  setAuthData: React.Dispatch<React.SetStateAction<any>>;
  handleLogin: () => void;
  handleLogout: () => void;
  fetchSession: () => void;
  userPool: any;
  getUserData: () => void;
  signUp: (emai: string, password: string, cb?: () => void) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  authData: null,
  setAuthData: () => { },
  handleLogin: () => { },
  handleLogout: () => { },
  fetchSession: () => { },
  userPool: null,
  getUserData: () => { },
  signUp: () => { },
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authData, setAuthData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailState, setEmailState] = useState('');
  const [isStartedGettingUser, setIsStartedGettingUser] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userPool, setUserPool] = useState()
  const [currentUser, setCurrentUser] = useState()

  const handleLogin = (emailArg) => {
    setIsAuthenticated(true);
    getUserData(emailArg || emailState);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setAuthData(null);
  };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (!isStartedGettingUser) {
  //       console.log(location.pathname, location.search)
  //       console.trace()
  //       console.log(cachedRoutes.some(itm => searchParams.get("requested").includes(itm)))
  //       navigate((searchParams.get("requested") && cachedRoutes.some(itm => searchParams.get("requested").includes(itm))) ? `${searchParams.get("requested")}` : '/home');
  //     }
  //   }
  // }, [isAuthenticated])

  const getUserData = async (email?: string) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/api/v1/users/details/${email || emailState}`);
      const data = response.data;
      console.log(data)
      dispatch(setUser(data))
      dispatch(setReports(data.reports_created))
      setIsLoading(false);

    } catch (e) {
      console.log(e)
      setIsLoading(false);
      setIsStartedGettingUser(false);
    }
  }

  const fetchSession = () => {
    const pool = userPool || new CognitoUserPool({
      UserPoolId: awsConfig.userPoolId,
      ClientId: awsConfig.clientId,
    })
    if (!userPool) {
      setUserPool(pool);
    }
    const cognitoUser = currentUser || pool?.getCurrentUser();
    if (!currentUser) {
      setCurrentUser(cognitoUser)
    }

    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          setIsLoading(false);
          console.error("Error getting session:", err);
          return;
        }
        if (session.isValid()) {
          setIsLoading(true);
          setIsAuthenticated(true);
          cognitoUser.getUserAttributes((v, attributes) => {
            console.log(v, attributes)
            const email = attributes?.[0]?.getValue() || ''
            setEmailState(email);

            if (!isStartedGettingUser) {
              setIsStartedGettingUser(true)
              getUserData(email).then(() => {
                console.log(location.pathname, location.search)
                console.trace()
                if (location.pathname === '/login') {
                  const nextUrl = (searchParams.get("requested") && cachedRoutes.some(itm => searchParams.get("requested").includes(itm))) ? `${searchParams.get("requested")}` : '/home'
                  console.log('NEXT URL', nextUrl)
                  // console.log(cachedRoutes.some(itm => searchParams.get("requested").includes(itm)))
                  navigate(nextUrl);
                } else {
                  navigate(cachedRoutes.includes(location.pathname) ? `${location.pathname}${location.search}` : '/home');
                }
              });
            }

          });
        }
      });
    }
  }

  const signUp = (email, password, cb = () => { }) => {
    const pool = userPool || new CognitoUserPool({
      UserPoolId: awsConfig.userPoolId,
      ClientId: awsConfig.clientId,
    })
    if (!userPool) {
      setUserPool(pool);
    }

    const attributeList = [];
    const dataEmail = {
      Name: "email",
      Value: email,
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);

    pool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        console.error("Sign up error:", err.message || JSON.stringify(err));
        return;
      }
      const cognitoUser = result?.user;
      setCurrentUser(cognitoUser)
      console.log("User name is " + cognitoUser?.getUsername());
      // localStorage.setItem("userData", JSON.stringify(result));
      cb()
    });
  };

  useEffect(() => {
    fetchSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userPool,
        isAuthenticated,
        setIsAuthenticated,
        authData,
        setAuthData,
        handleLogin,
        handleLogout,
        fetchSession,
        currentUser,
        getUserData,
        setCurrentUser,
        signUp,
      }}
    >
      <Spin spinning={isLoading}>
        {children}
      </Spin>
    </AuthContext.Provider>
  );
};
